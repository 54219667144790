<template>
    <div class="page">
        <h1>This is an zubair-sec page</h1>
        <enfooter></enfooter>

    </div>
</template>

<script>
    import enfooter from '@/components/enfooter.vue'

    export default {
        name: 'zubair-sec',
        components: {
            enfooter
        }
    }
</script>

