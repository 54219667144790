<template>
  <div class="page">
    <section
      v-if="errored"
      style="
        height: 500px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        background-color: #757575;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        color: white;
        align-items: center;
      "
    >
      <p style="width: 100%; text-align: center">
        We're sorry, we're not able to retrieve this information at the moment,
        please try back later
      </p>
    </section>

    <section v-else>
      <div v-if="loading">
        <div
          class="loading loading-lg"
          style="
            height: 100vh;
            display: block;
            position: relative;
            background-color: #757575;
          "
        ></div>
      </div>

      <div v-if="info">
        <picture
          style="
            height: 500px;
            background-color: #757575;
            margin-bottom: 20px;
            background-position: center center;
            position: relative;
            display: block;
          "
        >
          <source
            media="(min-width: 1280px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_web.path"
          />
          <source
            media="(min-width: 840px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_tab.path"
          />
          <source
            media="(min-width: 600px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
          />
          <img
            v-bind:src="baseurl + info.page_placeholder_image_mobile.path"
            v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
            v-bind:data-srcset="
              baseurl + info.page_placeholder_image_mobile.path
            "
            v-bind:alt="info.page_title"
            v-bind:title="info.page_title"
            v-bind:aria-label="info.page_title"
            style="width: 100%; height: 500px; object-fit: cover"
          />

          <div
            style="
              width: 90%;
              text-align: center;
              color: #ffffff;
              margin-left: 5%;
              margin-right: 5%;
              position: absolute;
              display: block;
              top: 50%;
              margin: auto;
              left: 0;
              right: 0;
            "
          >
            <h1
              style="
                margin-bottom: 0px;
                padding-top: 20px;
                font-weight: 700;
                line-height: 1;
                text-shadow: 5px 5px 20px #000000;
              "
            >
              {{ info.page_title }}
            </h1>
          </div>
        </picture>

        <div class="container grid-xl" style="margin-top: 45px">
          <div class="columns">
            <div v-html="info.page_content"></div>
          </div>
        </div>
        <!--  -->
        <div
          class="container grid-xl"
          style="margin-top: 50px; margin-bottom: 50px"
        >
          <h2 style="margin-bottom: 40px">Leadership Team</h2>
          <div class="columns">
            <div
              class="column col-3 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
              v-for="team in teams"
              :key="team._id"
              style="margin-bottom: 25px"
            >
              <router-link
                :to="'/en/about/the-team/' + team.name_slug"
                style="text-decoration: none"
                class="HoverBox"
              >
                <div class="IMGHolder">
                  <img
                    v-bind:src="
                      `https://zubaircorp.com/eclips/cms/storage/uploads/` +
                        team.profile_image.path
                    "
                    class="ThumbIMG"
                    :title="team.name"
                    :alt="team.name"
                    loading="lazy"
                  />
                  <div class="card-header">
                    <div
                      class="card-title h5"
                      style="font-size: 18px; text-decoration: none"
                    >
                      {{ team.name }}
                    </div>
                    <div
                      class="card-subtitle"
                      style="
                        color: #585858;
                        font-size: 14px;
                        text-decoration: none;
                      "
                    >
                      {{ team.designation }}
                    </div>
                    <!-- <div
                    class="card-subtitle"
                    style="
                      color: rgb(144 144 144);
                      font-size: 13px;
                      text-decoration: none;
                    "
                  >
                    {{ team.corporation_name }}
                  </div> -->
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!--  -->
        <div
          class="container grid-xl"
          style="margin-top: 50px; margin-bottom: 50px"
        >
          <h2 style="margin-bottom: 40px">Management Team</h2>
          <div class="columns">
            <div
              class="column col-3 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
              v-for="team in secteams"
              :key="team._id"
              style="margin-bottom: 25px"
            >
              <router-link
                :to="'/en/about/the-team/management-team/' + team.name_slug"
                style="text-decoration: none"
                class="HoverBox"
              >
                <div class="IMGHolder">
                  <img
                    v-bind:src="
                      `https://zubaircorp.com/eclips/cms/storage/uploads/` +
                        team.profile_image.path
                    "
                    class="ThumbIMG"
                    :title="team.name"
                    :alt="team.name"
                    loading="lazy"
                  />
                  <div class="card-header">
                    <div
                      class="card-title h5"
                      style="font-size: 18px; text-decoration: none"
                    >
                      {{ team.name }}
                    </div>
                    <div
                      class="card-subtitle"
                      style="
                        color: #585858;
                        font-size: 14px;
                        text-decoration: none;
                      "
                    >
                      {{ team.designation }}
                    </div>
                    <!-- <div
                    class="card-subtitle"
                    style="
                      color: rgb(144 144 144);
                      font-size: 13px;
                      text-decoration: none;
                    "
                  >
                    {{ team.corporation_name }}
                  </div> -->
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </section>

    <enfooter></enfooter>
  </div>
</template>

<script>
import enfooter from "@/components/enfooter.vue";
import axios from "axios";

export default {
  name: "the-team",

  data() {
    return {
      info: null,
      teams: null,
      secteams: null,
      loading: true,
      errored: false,
      baseurl: "https://zubaircorp.com/eclips",
    };
  },

  components: {
    enfooter,
  },

  mounted() {
    axios
      .get(
        this.baseurl +
          "/cms/api/singletons/get/TheTeam?token=72d0a17a1cfa0d99e532c39232bdd5"
      )
      .then((response) => {
        this.info = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      });
    //   Team Load
    axios
      .get(
        this.baseurl +
          "/cms/api/collections/get/team?token=72d0a17a1cfa0d99e532c39232bdd5"
      )
      .then((response) => {
        this.teams = response.data.entries;
        //console.log("Team", response.data.entries);
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })

      .finally(() => (this.loading = false));
    //  Scond Team
    axios
      .get(
        this.baseurl +
          "/cms/api/collections/get/management_team?token=72d0a17a1cfa0d99e532c39232bdd5"
      )
      .then((response) => {
        this.secteams = response.data.entries;
        //console.log("management_team", response.data.entries);
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })

      .finally(() => (this.loading = false));
    //
  },
};
</script>

<style scoped>
img.ThumbIMG {
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
  transition-duration: 0.3s;
  object-fit: contain;
  width: 100%;
}
img.ThumbIMG:hover {
  filter: none;
}
.IMGHolder {
  position: relative;
}
</style>
