var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info)?_c('div',{staticClass:"home"},[(_vm.errored)?_c('section',{staticStyle:{"height":"100vh","display":"flex","background-color":"#757575","-ms-flex-align":"center","-webkit-align-items":"center","-webkit-box-align":"center","color":"white","align-items":"center"}},[_c('p',[_vm._v(" We're sorry, we're not able to retrieve this information at the moment, please try back later ")])]):_c('section',[(_vm.loading)?_c('div',[_c('div',{staticClass:"loading loading-lg",staticStyle:{"height":"100vh","display":"block","position":"relative","background-color":"#757575"}})]):_c('div',[(_vm.window.width > '840')?_c('div',[_c('splide',{attrs:{"options":_vm.options}},_vm._l((_vm.info.entries),function(entries){return _c('splide-slide',{key:entries.src,staticStyle:{"background-size":"cover"},style:({
              backgroundImage:
                'url(' + _vm.baseurl + entries.block_a_image_web.path + ')',
            })},[_c('div',{staticClass:"container grid-xl",attrs:{"id":"SliderHolder"}},[_c('div',{staticClass:"columns",style:({ color: entries.block_a_color })},[_c('div',{staticStyle:{"width":"100%"}},[_c('h2',{staticStyle:{"font-weight":"600","line-height":"1"}},[_vm._v(" "+_vm._s(entries.block_a_title)+" ")])]),_c('div',{staticStyle:{"width":"100%","margin-bottom":"20px"}},[_c('div',{staticClass:"ContHolder"},[_vm._v(" "+_vm._s(entries.block_a_content)+" ")])]),_c('router-link',{staticClass:"btn",style:({ color: entries.block_a_color }),attrs:{"to":entries.block_link}},[_vm._v(_vm._s(entries.black_btn_name))])],1)])])}),1)],1):(_vm.window.width > '600')?_c('div',[_c('splide',{attrs:{"options":_vm.options}},_vm._l((_vm.info.entries),function(entries){return _c('splide-slide',{key:entries.src,staticStyle:{"background-size":"cover"},style:({
              backgroundImage:
                'url(' + _vm.baseurl + entries.block_a_image_tab.path + ')',
            })},[_c('div',{staticClass:"container grid-xl",attrs:{"id":"SliderHolder"}},[_c('div',{staticClass:"columns",style:({ color: entries.block_a_color })},[_c('div',{staticStyle:{"width":"100%"}},[_c('h2',{staticStyle:{"font-weight":"600","line-height":"1"}},[_vm._v(" "+_vm._s(entries.block_a_title)+" ")])]),_c('div',{staticStyle:{"width":"100%","margin-bottom":"20px"}},[_c('div',{staticClass:"ContHolder"},[_vm._v(" "+_vm._s(entries.block_a_content)+" ")])]),_c('router-link',{staticClass:"btn",style:({ color: entries.block_a_color }),attrs:{"to":entries.block_link}},[_vm._v(_vm._s(entries.black_btn_name))])],1)])])}),1)],1):(_vm.window.width < '600')?_c('div',[_c('splide',{attrs:{"options":_vm.options}},_vm._l((_vm.info.entries),function(entries){return _c('splide-slide',{key:entries.src,staticStyle:{"background-size":"cover"},style:({
              backgroundImage:
                'url(' + _vm.baseurl + entries.block_a_image_mobile.path + ')',
            })},[_c('div',{staticClass:"container grid-xl",attrs:{"id":"SliderHolder"}},[_c('div',{staticClass:"columns",style:({ color: entries.block_a_color })},[_c('div',{staticStyle:{"width":"100%"}},[_c('h2',{staticStyle:{"font-weight":"600","line-height":"1"}},[_vm._v(" "+_vm._s(entries.block_a_title)+" ")])]),_c('div',{staticStyle:{"width":"100%","margin-bottom":"20px"}},[_c('div',{staticClass:"ContHolder"},[_vm._v(" "+_vm._s(entries.block_a_content)+" ")])]),_c('router-link',{staticClass:"btn",style:({ color: entries.block_a_color }),attrs:{"to":entries.block_link}},[_vm._v(_vm._s(entries.black_btn_name))])],1)])])}),1)],1):_vm._e()])]),_c('enfooter',{staticClass:"HFooter"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }