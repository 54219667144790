import Vue from "vue";
import VueRouter from "vue-router";

import home from "../views/en/home";

//import application from '../views/application'

import about from "../views/en/about";
import chairmansmessage from "../views/en/about/chairmans-message";
import Thezubairstory from "../views/en/about/the-eclipse-story";
import ourorganisation from "../views/en/about/our-group";

import theteam from "../views/en/about/the-team";
import member from "../views/en/about/the-team/id";
import membersec from "../views/en/about/the-team/idsec";

import getintouch from "../views/en/about/get-in-touch";

import ourgroup from "../views/en/our-group";
import services from "../views/en/our-organisation/services";
import trading from "../views/en/our-organisation/trading";
import manufacturing from "../views/en/our-organisation/manufacturing";

import csr from "../views/en/csr";
import baitalzubairfoundation from "../views/en/csr/bait-al-zubair-foundation";
import zubairsec from "../views/en/csr/zubair-sec";
import otherinitiatives from "../views/en/csr/other-initiatives";

import workwithus from "../views/en/work-with-us";
import notfound from "../views/en/notfound";
import privacypolicy from "../views/en/privacy-policy";
import termsandconditions from "../views/en/terms-and-conditions";

// MAP
import map from "../views/map.vue";
//

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/en/",
  },
  {
    path: "/en/",
    name: "home",
    component: home,
  },

  {
    path: "/en/about",
    name: "about",
    component: about,
    //active: true,
    redirect: "/en/about/chairmans-message",
  },

  {
    path: "/en/about/chairmans-message",
    name: "chairmansmessage",
    component: chairmansmessage,
  },

  {
    path: "/en/about/the-eclipse-story",
    name: "Thezubairstory",
    component: Thezubairstory,
  },

  {
    path: "/en/about/our-group",
    name: "our-organisation",
    component: ourorganisation,
  },

  {
    path: "/en/about/the-team",
    name: "the-team",
    component: theteam,
  },
  {
    path: "/en/about/the-team/:memberid",
    name: "the-member",
    component: member,
  },
  {
    path: "/en/about/the-team/management-team",
    redirect: "/en/about/the-team/",
  },
  {
    path: "/en/about/the-team/management-team/:secmemberid",
    name: "the-member",
    component: membersec,
  },

  {
    path: "/en/about/get-in-touch",
    name: "get-in-touch",
    component: getintouch,
  },

  {
    path: "/en/our-organisation",
    name: "our-group",
    component: ourgroup,
    redirect: "/en/our-organisation/services",
  },

  {
    path: "/en/our-organisation/services",
    name: "services",
    component: services,
  },

  {
    path: "/en/our-organisation/trading",
    name: "trading",
    component: trading,
  },

  {
    path: "/en/our-organisation/manufacturing",
    name: "manufacturing",
    component: manufacturing,
  },

  {
    path: "/en/csr",
    name: "csr",
    component: csr,
    //redirect: '/en/csr/bait-al-zubair-foundation',
  },

  {
    path: "/en/csr/bait-al-zubair-foundation",
    name: "bait-al-zubair-foundation",
    component: baitalzubairfoundation,
  },

  {
    path: "/en/csr/zubair-sec",
    name: "zubair-sec",
    component: zubairsec,
  },

  {
    path: "/en/csr/other-initiatives",
    name: "other-initiatives",
    component: otherinitiatives,
  },

  {
    path: "/en/work-with-us",
    name: "work-with-us",
    component: workwithus,
  },

  {
    path: "/en/privacy-policy",
    name: "privacy-policy",
    component: privacypolicy,
  },

  {
    path: "/en/terms-and-conditions",
    name: "terms-and-conditions",
    component: termsandconditions,
  },

  // MAP
  {
    path: "/map",
    name: "map",
    component: map,
  },
  //
  {
    path: "/en/*",
    name: "notfound",
    component: notfound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  linkActiveClass: "page-active",
  linkExactActiveClass: "exact-active",

  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

export default router;
