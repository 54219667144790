<template>
  <div class="EFFECTT">
    <div id="FooterMaster" class="container grid-xl">
      <div class="columns" v-if="main">
        <div
          id="FLeft"
          class="column col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
        >
          <a
            rel="noopener"
            title="The Zubair Corporation - Facebook"
            href="https://www.facebook.com/TheZubairCorporation/"
            target="_blank"
            style="position: relative;top: -2px;"
            ><img
              alt="The Zubair Corporation - Facebook"
              class="SMIcons"
              width="20"
              height="20"
              src="../assets/facebook.svg"
          /></a>
          <a
            rel="noopener"
            title="The Zubair Corporation - Twitter"
            href="https://twitter.com/zubair_corp"
            target="_blank"
            ><img
              alt="The Zubair Corporation - Twitter"
              class="SMIcons"
              width="20"
              height="20"
              src="../assets/twitter.svg"
          /></a>
          <a
            rel="noopener"
            title="The Zubair Corporation - LinkedIn"
            href="https://www.linkedin.com/company/1264387"
            target="_blank"
            style="position: relative;top: -3px;"
            ><img
              alt="The Zubair Corporation - LinkedIn"
              class="SMIcons"
              width="20"
              height="20"
              src="../assets/linkedin.svg"
          /></a>
          <a
            rel="noopener"
            title="The Zubair Corporation - YouTube"
            href="https://www.youtube.com/channel/UCuqxLXckA0HAXP4d7x5Q12Q"
            target="_blank"
            style="position: relative;top: 0px;"
            ><img
              alt="The Zubair Corporation -YouTube"
              class="SMIcons"
              width="20"
              height="20"
              src="../assets/youtube.svg"
          /></a>

          <div style="display: inline;" v-if="this.$route.path === '/en/'">
            <div
              id="nav-sec"
              style="font-size: 14px;position: relative;top: -8px;"
              class="hide-lg"
            >
              <router-link class="text-dark white" to="/en/privacy-policy"
                >Privacy Policy</router-link
              >
              <router-link class="text-dark white" to="/en/terms-and-conditions"
                >Terms & Conditions</router-link
              >
            </div>
          </div>
          <div v-else style="display: inline;">
            <div
              id="nav-sec"
              style="font-size: 14px;position: relative;top: -8px;"
              class="hide-lg"
            >
              <router-link class="text-dark" to="/en/privacy-policy"
                >Privacy Policy</router-link
              >
              <router-link class="text-dark" to="/en/terms-and-conditions"
                >Terms & Conditions</router-link
              >
            </div>
          </div>
        </div>

        <div
          id="FRight"
          class="column col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
        >
          © {{ new Date().getFullYear() }} <span>Eclipse Investments</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "enfooter",

  computed: {
    main() {
      return this.$route.path.indexOf("/en") === 0;
    },
  },
};
</script>

<style scoped>
div#FooterMaster {
  padding-top: 30px;
  padding-bottom: 30px;

  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 500ms;
}
div#FLeft {
  text-align: left;
  padding-top: 15px;
}
div#FLeft_ar {
  text-align: right;
  padding-top: 15px;
}
div#FRight {
  text-align: right;
  padding-top: 15px;
}
div#FRight_ar {
  text-align: left;
  padding-top: 15px;
}
img.SMIcons {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.EFFECTT.HFooter {
  background: rgb(32, 32, 32);
  background: linear-gradient(
    0deg,
    rgba(32, 32, 32, 0.7399334733893557) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

a.text-dark.white {
  color: white !important;
}

@media only screen and (max-width: 600px) {
  div#FLeft,
  div#FRight,
  div#FLeft_ar,
  div#FRight_ar {
    text-align: center;
    padding-top: 15px;
  }

  .EFFECTT.HFooter {
    background: rgb(32, 32, 32);
    background: linear-gradient(
      0deg,
      rgba(32, 32, 32, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
</style>
