<template>
  <div class="page">
    <section
      v-if="errored"
      style="
        height: 500px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        background-color: #757575;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        color: white;
        align-items: center;
      "
    >
      <p style="width: 100%; text-align: center">
        We're sorry, we're not able to retrieve this information at the moment,
        please try back later
      </p>
    </section>

    <section v-else>
      <div v-if="loading">
        <div
          class="loading loading-lg"
          style="
            height: 100vh;
            display: block;
            position: relative;
            background-color: #757575;
          "
        ></div>
      </div>

      <div v-if="info">
        <picture
          style="
            height: 500px;
            background-color: #757575;
            margin-bottom: 20px;
            background-position: center center;
            position: relative;
            display: block;
          "
        >
          <source
            media="(min-width: 1280px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_web.path"
          />
          <source
            media="(min-width: 840px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_tab.path"
          />
          <source
            media="(min-width: 600px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
          />
          <img
            v-bind:src="baseurl + info.page_placeholder_image_mobile.path"
            v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
            v-bind:data-srcset="
              baseurl + info.page_placeholder_image_mobile.path
            "
            v-bind:alt="info.page_title"
            v-bind:title="info.page_title"
            v-bind:aria-label="info.page_title"
            style="width: 100%; height: 500px; object-fit: cover"
          />

          <div
            style="
              width: 90%;
              text-align: center;
              color: #ffffff;
              margin-left: 5%;
              margin-right: 5%;
              position: absolute;
              display: block;
              top: 50%;
              margin: auto;
              left: 0;
              right: 0;
            "
          >
            <h1
              style="
                margin-bottom: 0px;
                padding-top: 20px;
                font-weight: 700;
                line-height: 1;
                text-shadow: 5px 5px 20px #000000;
              "
            >
              {{ info.page_title }}
            </h1>
          </div>
        </picture>

        <div
          class="container grid-xl"
          style="margin-top: 50px; margin-bottom: 50px"
        >
          <div v-if="team">
            <div class="columns MemberTitles">
              <div class="column col-auto">
                <img
                  v-bind:src="
                    `https://zubaircorp.com/eclips/cms/storage/uploads/` +
                      team.profile_image.path
                  "
                  class="ThumbIMG"
                  :title="team.name"
                  :alt="team.name"
                  loading="lazy"
                />
              </div>
              <div class="column col-xs-12 col-sm-12 col-md-12">
                <h2 style="margin-top: 20px; margin-bottom: 0">
                  {{ team.name }}
                </h2>
                <div
                  class="card-subtitle"
                  style="color: #585858; font-size: 14px; text-decoration: none"
                >
                  {{ team.designation }}
                </div>
                <!-- <div
                  class="card-subtitle"
                  style="
                    color: rgb(144 144 144);
                    font-size: 13px;
                    text-decoration: none;
                  "
                >
                  {{ team.corporation_name }}
                </div> -->
                <div style="margin-bottom: 25px; margin-top: 25px">
                  <div v-if="team.content" v-html="team.content"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div
              class="column col-12"
              style="margin-bottom: 25px; margin-top: 25px; text-align: right"
            >
              <router-link
                to="/en/about/the-team/"
                style="text-decoration: none"
                class="btn"
                >Back</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <enfooter></enfooter>
  </div>
</template>

<script>
import enfooter from "@/components/enfooter.vue";
import axios from "axios";

export default {
  name: "the-team",

  data() {
    return {
      info: null,
      team: null,
      loading: true,
      errored: false,
      baseurl: "https://zubaircorp.com/eclips",
    };
  },

  components: {
    enfooter,
  },

  mounted() {
    axios
      .get(
        this.baseurl +
          "/cms/api/singletons/get/TheTeam?token=72d0a17a1cfa0d99e532c39232bdd5"
      )
      .then((response) => {
        this.info = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      });
    //   Team Load
    axios
      .get(
        this.baseurl +
          "/cms/api/collections/get/management_team?token=72d0a17a1cfa0d99e532c39232bdd5&filter[name_slug]=" +
          this.$route.params.secmemberid
      )
      .then((response) => {
        this.team = response.data.entries[0];
        console.log("The Man", response.data.entries[0]);
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })

      .finally(() => (this.loading = false));
  },
};
</script>

<style scoped>
img.ThumbIMG {
  height: auto;
  width: 100%;
}
.NoIMG {
  height: 150px;
  width: 150px;
  background: #b8b8b8;
  border-radius: 50%;
}
.IMGHolder {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.card.HovMe:hover {
  border: 0;
  box-shadow: 0 0.25rem 1rem rgb(48 55 66 / 10%);
  height: 100%;
  cursor: pointer;
}
.columns.MemberTitles {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .columns.MemberTitles {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}
</style>
