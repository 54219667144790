<template>
  <div class="mapHolder">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29251.056501322717!2d58.361335!3d23.590627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8e0019e98be41d%3A0x90afba6273072959!2z2YXYpNiz2LPYqSDYp9mE2LLYqNmK2LEgWnViYWlyIENvcnBvcmF0aW9u!5e0!3m2!1sen!2som!4v1678853254529!5m2!1sen!2som"
      width="600"
      height="450"
      style="border: 0px;
        height: 100vh;
        position: absolute;
        top: 0;
        z-index: 5;
        width: 100vw;"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "mapHolder",
};
</script>
