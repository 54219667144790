<template>
  <div class="page">
    <section
      v-if="errored"
      style="
        height: 500px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        background-color: #757575;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        color: white;
        align-items: center;
      "
    >
      <p style="width: 100%; text-align: center">
        We're sorry, we're not able to retrieve this information at the moment,
        please try back later
      </p>
    </section>

    <section v-else>
      <div v-if="loading">
        <div
          class="loading loading-lg"
          style="
            height: 100vh;
            display: block;
            position: relative;
            background-color: #757575;
          "
        ></div>
      </div>

      <div v-if="info">
        <picture
          style="
            height: 500px;
            background-color: #757575;
            margin-bottom: 20px;
            background-position: center center;
            position: relative;
            display: block;
          "
        >
          <source
            media="(min-width: 1280px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_web.path"
          />
          <source
            media="(min-width: 840px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_tab.path"
          />
          <source
            media="(min-width: 600px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
          />
          <img
            v-bind:src="baseurl + info.page_placeholder_image_mobile.path"
            v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
            v-bind:data-srcset="
              baseurl + info.page_placeholder_image_mobile.path
            "
            v-bind:alt="info.page_title"
            v-bind:title="info.page_title"
            v-bind:aria-label="info.page_title"
            style="width: 100%; height: 500px; object-fit: cover"
          />

          <div
            style="
              width: 90%;
              text-align: center;
              color: #ffffff;
              margin-left: 5%;
              margin-right: 5%;
              position: absolute;
              display: block;
              top: 50%;
              margin: auto;
              left: 0;
              right: 0;
            "
          >
            <h1
              style="
                margin-bottom: 0px;
                padding-top: 20px;
                font-weight: 700;
                line-height: 1;
                text-shadow: 5px 5px 20px #000000;
              "
            >
              {{ info.page_title }}
            </h1>
          </div>
        </picture>

        <div
          class="container grid-xl"
          style="margin-bottom: 30px; margin-top: 30px"
        >
          <div class="columns">
            <div v-html="info.page_content"></div>
          </div>
        </div>
      </div>
    </section>

    <div class="container grid-xl">
      <hr
        style="
          width: 60%;
          margin: auto;
          margin-top: 30px;
          margin-bottom: 50px;
          border: 0.5px solid #dedede;
        "
      />

      <section v-if="errored2">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>

      <section v-else style="margin-bottom: 30px; margin-top: 30px">
        <div v-if="loading2">
          <div class="loading loading-lg"></div>
        </div>

        <div v-else>
          <div class="columns">
            <div
              class="column col-6 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
              v-for="item in media"
              :key="item._id"
              style="margin-bottom: 50px"
            >
              <div class="columns">
                <div
                  class="column col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                >
                  <img
                    width="150px"
                    height="150px"
                    v-bind:title="item.title"
                    v-bind:alt="item.title"
                    v-bind:src="baseurl + item.logo.path"
                    class="img-responsive p-centered"
                    style="padding-bottom: 20px"
                  />
                </div>
                <div
                  class="column col-9 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
                >
                  <h2 style="font-size: 23px">{{ item.title }}</h2>
                  <div style="font-size: 14px" v-html="item.content"></div>

                  <div class="columns">
                    <div class="column col-auto">
                      <img
                        width="30px"
                        height="30px"
                        v-bind:title="'Call ' + item.title"
                        v-bind:alt="'Call ' + item.title"
                        class="AandH_Icon"
                        src="../../../assets/phone-call.svg"
                      />
                      <small
                        ><a v-bind:href="'tel:' + item.phone">{{
                          item.phone
                        }}</a></small
                      >
                    </div>
                    <div class="column col-auto">
                      <img
                        width="30px"
                        height="30px"
                        v-bind:title="'Visit ' + item.title"
                        v-bind:alt="'Visit ' + item.title"
                        class="AandH_Icon"
                        src="../../../assets/world-wide-web.svg"
                      />
                      <small
                        ><a
                          target="_blank"
                          v-bind:href="'http://' + item.web"
                          >{{ item.web.substring(0, 30) }}</a
                        ></small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <enfooter></enfooter>
  </div>
</template>

<script>
import enfooter from "@/components/enfooter.vue";
import axios from "axios";

export default {
  name: "bait-al-zubair-foundation",

  data() {
    return {
      info: null,
      media: null,
      loading: true,
      errored: false,
      loading2: true,
      errored2: false,

      baseurl: "https://zubaircorp.com/eclips",
    };
  },

  components: {
    enfooter,
  },

  mounted() {
    axios
      .get(
        this.baseurl +
          "/cms/api/singletons/get/CSRandSustainability?token=72d0a17a1cfa0d99e532c39232bdd5"
      )
      .then((response) => {
        this.info = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));

    axios
      .get(
        this.baseurl +
          "/cms/api/collections/get/CSRandSustainability/?token=72d0a17a1cfa0d99e532c39232bdd5&sort[media_date]=-1"
      )
      .then((response) => {
        this.media = response.data.entries;
        //console.log(response)
      })
      .catch((error) => {
        console.log(error);
        this.errored2 = true;
      })
      .finally(() => (this.loading2 = false));
  },
};
</script>

<style>
img.AandH_Icon {
  width: 30px;
  height: 30px;
  position: relative;
  top: 10px;
}
</style>
